import React from 'react';
import { Grid } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { FaqArticleType } from '../../../../types/faqArticleType';
import BlockContent from '../../components/block-content/BlockContent';
import { FormBlock } from '../../components/block-content/components/Form/FormBlock';
import { AccordionBlock } from '../../components/block-content/components/types/components/Accordion';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

type Props = {
  article: FaqArticleType;
};

const FAQArticle = ({
  article: {
    title,
    ingress,
    dynamicForm,
    body,
    slug,
    seoConfig,
    frequentlyAskedQuestions,
    frequentlyAskedQuestionItems,
  },
}: Props) => {
  const windowPath = useWindowLocation();
  const hasFAQElements = frequentlyAskedQuestionItems?.length > 0;
  return (
    <LayoutWithMainContent
      title={title}
      description={ingress || ''}
      url={windowPath}
      gtmArgs={{
        page_type: 'faqArticle',
        pageCategory: 'Hjelp og kontakt',
        contentId: slug,
        headline: title,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoDescription={seoConfig?.introduction || ingress || ''}
      seoTitle={seoConfig?.title || title}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      <Grid>
        <StyledGridCol>
          <BreadCrumb />
          <Text variant={TextVariant.Header1}>{title}</Text>
          {!hasFAQElements && <BlockContent value={body} />}
          {frequentlyAskedQuestions?.map((question) => (
            <AccordionBlock value={question} key={question.title} />
          ))}
          {frequentlyAskedQuestionItems?.map((faqItem) => {
            const accordionConfig = { title: faqItem.question, text: faqItem.answer.concat(faqItem.faqItemText) };
            return <AccordionBlock value={accordionConfig} key={faqItem.id} />;
          })}
          {!hasFAQElements && <FormBlock value={{ dynamicForm }} centerAlignReceipt />}
          {hasFAQElements && (
            <BodyTextWrapper>
              <BlockContent value={body} />
            </BodyTextWrapper>
          )}
        </StyledGridCol>
      </Grid>
    </LayoutWithMainContent>
  );
};

const StyledGridCol = styled.div`
  grid-column: 3 / 11;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const BodyTextWrapper = styled.div`
  margin-top: ${spacing.space56};
`;

export default FAQArticle;
